/**
 * Firebase project settings
 */

import { merge } from 'lodash';

const authTokenUrl = 'https://www.googleapis.com/oauth2/v4/token';
const authCodeUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
const mobileRedirectPath = '/auth-handler';

const settings = {
  prod: {
    googleCdn: {
      host: 'https://download.pop.com',
    },
    firebaseConfig: {
      apiKey: 'AIzaSyDGmsswv_AXiNfvogwMrBBgRjWK7oTb6VA',
      authDomain: 'pop.com',
      databaseURL: 'https://scrn-prod.firebaseio.com',
      projectId: 'scrn-prod',
      storageBucket: 'scrn-prod.appspot.com',
      messagingSenderId: '164108522845',
      appId: '1:164108522845:web:b39fe309cd9034cf',
    },
    googleClientId: {
      webClientId: '164108522845-p8h14uvegbqatkgsdg8gn5i5gbgkd0qj.apps.googleusercontent.com',
      webClientReverseId: 'com.googleusercontent.apps.164108522845-p8h14uvegbqatkgsdg8gn5i5gbgkd0qj',
      iosClientId: '164108522845-dultpa7sks6vrnpqgj91lk2cfli4pdvb.apps.googleusercontent.com',
      iosClientReverseId: 'com.googleusercontent.apps.164108522845-dultpa7sks6vrnpqgj91lk2cfli4pdvb',
    },
    firebaseCloudFunctionUrlOrigin: 'https://pop.com',
  },
  beta: {
    googleCdn: {
      host: 'https://storage.googleapis.com/pop-vo.appspot.com',
    },
    firebaseConfig: {
      apiKey: 'AIzaSyDL7QKmsjaQ1knlSRhKqikhLOf11qcooyk',
      authDomain: 'pop-vo.firebaseapp.com',
      databaseURL: 'https://pop-vo-default-rtdb.firebaseio.com',
      projectId: 'pop-vo',
      storageBucket: 'pop-vo.firebasestorage.app',
      messagingSenderId: '117938112290',
      appId: '1:117938112290:web:da9c8818f7f2c2740f877b',
    },
    googleClientId: {
      webClientId: '117938112290-nct57p2jq4h9viu2h2kumse6dkf3k4va.apps.googleusercontent.com',
      webClientReverseId: 'com.googleusercontent.apps.117938112290-nct57p2jq4h9viu2h2kumse6dkf3k4va',
    },
    firebaseCloudFunctionUrlOrigin: 'https://beta.pop.com',
  },
  ci: {
    googleCdn: {
      host: 'https://storage.googleapis.com/iteleport-ci.appspot.com',
    },
    firebaseConfig: {
      apiKey: 'AIzaSyDyGBya9BL_-_6oJnWTSW7SKUncPrubUmo',
      authDomain: 'ci.pop.com',
      databaseURL: 'https://iteleport-ci.firebaseio.com',
      projectId: 'iteleport-ci',
      storageBucket: 'iteleport-ci.appspot.com',
      messagingSenderId: '417481078658',
    },
    googleClientId: {
      webClientId: '???',
      webClientReverseId: '???',
      iosClientId: '???',
      iosClientReverseId: '???',
    },
    firebaseCloudFunctionUrlOrigin: 'https://ci.pop.com',
  },
  dev: {
    googleCdn: {
      host: 'https://storage.googleapis.com/iteleport-nightly.appspot.com',
    },
    firebaseConfig: {
      apiKey: 'AIzaSyC7OE533SsweZlE2uLWp68NM2XWT-O7Kck',
      authDomain: 'dev.pop.com',
      databaseURL: 'https://iteleport-nightly.firebaseio.com',
      projectId: 'iteleport-nightly',
      storageBucket: 'iteleport-nightly.appspot.com',
      messagingSenderId: '505390686937',
    },
    googleClientId: {
      webClientId: '505390686937-ql159qeo1lucheaqgvf7a6m5njpustu0.apps.googleusercontent.com',
      webClientReverseId: 'com.googleusercontent.apps.505390686937-ql159qeo1lucheaqgvf7a6m5njpustu0',
      iosClientId: '505390686937-qll83oc5fa6juj0hd0pa0rr3fmlfdt7k.apps.googleusercontent.com',
      iosClientReverseId: 'com.googleusercontent.apps.505390686937-qll83oc5fa6juj0hd0pa0rr3fmlfdt7k',
    },
    firebaseCloudFunctionUrlOrigin: 'https://dev.pop.com',
  },
};

export const selectedSettingsKey = (process.env.FIREBASE_ENV || 'dev') as unknown as keyof typeof settings;
const pristineSelectedSettings = settings[selectedSettingsKey];

// Firebase auth user password validation is possible via the REST api as documented here:
// https://firebase.google.com/docs/reference/rest/auth#section-sign-in-email-password
//
// Additionally, when running the firebase emulator, the identity-toolkit needs to point
// to the emulator's local ip and port. Note: the PORT number is hardcoded to 9099 (the
// default for the auth emulator), which may need to be adjusted.
const googleIdentityToolkitLoginUrl = 'identitytoolkit.googleapis.com/v1/accounts:signInWithPassword';
const googleIdentityToolkitPrefix = globalThis.process?.env?.FUNCTIONS_EMULATOR
  ? 'http://127.0.0.1:9099/'
  : 'https://';
const identityToolkitLoginUrl = `${googleIdentityToolkitPrefix}${googleIdentityToolkitLoginUrl}?key=${pristineSelectedSettings.firebaseConfig.apiKey}`;

export const selectedSettings = merge(pristineSelectedSettings, {
  googleClientId: {
    authTokenUrl,
    authCodeUrl,
    identityToolkitLoginUrl,
    mobileRedirectPath,
    mobileRedirectOrigin: pristineSelectedSettings.firebaseConfig.authDomain,
    calendarAppScriptClientId: '164108522845-p57gpjtihvosufh2vcv1sbv84gn7qt5m.apps.googleusercontent.com',
  },
  ...(globalThis.localStorage?.firebaseConfigOverrides
    ? JSON.parse(globalThis.localStorage?.firebaseConfigOverrides)
    : {}),
  ...(globalThis.process?.env.FIREBASE_CONFIG_OVERRIDES
    ? JSON.parse(globalThis.process?.env.FIREBASE_CONFIG_OVERRIDES)
    : {}),
  ...(globalThis.process?.env?.FUNCTIONS_EMULATOR
    ? { origin: 'http://localhost:8101' }
    : {
        origin:
          globalThis.location?.origin ?? `https://${pristineSelectedSettings.firebaseConfig.authDomain}`,
      }),

  // Note: once our okta integration is in OIN, we will switch to using our Okta app's client id.
  // For now, client-id is looked up from the DB.
  // okta: {
  //   clientId: '0oao1ukgbxeLJ9GTO5d6',
  // },
});
export type FirebaseProjectKey = 'prod' | 'ci' | 'dev' | 'j' | 'mh';

/*

** NOTE: localhost-dev-helper.tsx automates all of the stuff below **

client (in dev tools):
----------------------

localStorage.firebaseConfigOverrides = JSON.stringify({
  firebaseConfig: {
    databaseURL: 'http://localhost:9000?ns=iteleport-nightly',
  },
  firebaseCloudFunctionUrlOrigin: 'http://localhost:5001/iteleport-nightly/us-central1',
  // firebaseCloudFunctionUrlOrigin: 'https://iteleport.ngrok.io/iteleport-nightly/us-central1',
})


cloud-functions, darwin/win32/linux (cli, prepend to `FIREBASE_CONFIG_OVERRIDES=[output of JSON.stringify] npx ...`):
---------------------------------------------------------------------------------------------------------------------

FIREBASE_CONFIG_OVERRIDES='{"firebaseConfig":{"databaseURL":"http://localhost:9000?ns=iteleport-nightly"},"firebaseCloudFunctionUrlOrigin":"https://iteleport.ngrok.io/iteleport-nightly/us-central1"}' npx firebase emulators:start --project iteleport-nightly --import export

*/
